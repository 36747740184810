import { CfSectionQuote } from 'interfaces/Contentful/CfSection';
import { FC } from 'react';
import cn from 'classnames';
import Image from 'next/image';

//styles
import styles from './sectionQuote.module.scss';

interface Props {
  content: CfSectionQuote;
}

const SectionQuote: FC<Props> = ({ content }) => {
  const imageSrc = content.imageUrl.startsWith('//') ? `https:${content.imageUrl}` : content.imageUrl;

  const quotee = `${content.nameOfQuotedPerson}, ${content.titleOfQuotedPerson},`;

  return (
    <section className='padding-y-xl'>
      <div className='container max-width-lg'>
        <div className='grid'>
          <div className='col-12 col-7@sm col-5@md position-relative'>
            <Image src={imageSrc} layout='fill' objectFit='cover' />
          </div>
          <div className='col-12 col-5@sm col-7@md'>
            <div className={styles.sectionRightCol}>
              <img src='/images/quote.png' className={cn(styles.quoteIcon, 'margin-bottom-md text-center')} />
              <blockquote className='text-component text-center'>
                <p className='text-lg text-xl@md'>{content.quote}</p>
                <cite>
                  {quotee}
                  <br />
                  {content.companyOfQuotedPerson}
                </cite>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionQuote;
